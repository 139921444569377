import { ApiCore } from './ApiCore';

const Api = new ApiCore();

//Auth
export const login = (data)=> Api.postNotAuth(`/ecommerce/login`, data);
export const logout = ()=> Api.post(`/ecommerce/logout`,{});
export const register = (data)=> Api.postNotAuth(`/ecommerce/register`, data);
export const sendLinkResetPassword = (data)=> Api.postNotAuth(`/ecommerce/send-link-reset-password`, data);
export const resetPassword = (data)=> Api.postNotAuth(`/ecommerce/reset-password`, data);

//categories
export const getCategories = () => Api.getNotAuth(`/ecommerce/categories`);
export const getCategoriesById = (id) => Api.get(`/ecommerce/categories/${id}`);
export const createCategories = (data)=> Api.post(`/ecommerce/categories`, data);
export const updateCategories = (data)=> Api.put(`/ecommerce/categories`, data);
export const deleteCategories = (id)=> Api.delete(`/ecommerce/categories/${id}`);

//products
export const getProducts = () => Api.getNotAuth(`/ecommerce/products`);
export const getProductsPaginate = (params) => Api.getNotAuth(`/ecommerce/products-paginate${params}`);
export const getProductsById = (id) => Api.getNotAuth(`/ecommerce/products/${id}`);
export const getProductsBySlug = (slug) => Api.getNotAuth(`/ecommerce/products/slug/${slug}`);
export const getProductsSearch = (search) => Api.getNotAuth(`/ecommerce/products-search${search}`);
export const getProductsFeatured = (search) => Api.getNotAuth(`/ecommerce/products-featured`);
export const createProducts = (data)=> Api.post(`/ecommerce/products`, data);
export const updateProducts = (data)=> Api.put(`/ecommerce/products`, data);
export const deleteProducts = (id)=> Api.delete(`/ecommerce/products/${id}`);

//wishlist
export const getWishlist = () => Api.get(`/ecommerce/wishlist`);
export const getAllWishlistByUserId = (userId) => Api.get(`/ecommerce/wishlist/${userId}`);
export const getSingleProductFromWishlist = (data) => Api.get(`/ecommerce/wishlist/${data.ec_user_id}/${data.ec_product_id}`);
export const createWishlist = (data)=> Api.post(`/ecommerce/wishlist`, data);
export const deleteWishlist = (id)=> Api.delete(`/ecommerce/wishlist/${id}`);

//WebPay
export const createdTransaction = (data)=> Api.post(`/ecommerce/webpay/created-transaction`, data);
export const commitTransaction = (data)=> Api.post(`/ecommerce/webpay/commit-transaction`, data);
export const refundTransaction = (data)=> Api.post(`/ecommerce/webpay/refound-transaction`, data);
export const getTransactionStatus = (data)=> Api.post(`/ecommerce/webpay/status-transaction`, data);

//OneClick
export const startInscription = (data)=> Api.post(`/ecommerce/oneclick/start`, data);
export const finishInscription = (data)=> Api.post(`/ecommerce/oneclick/finish`, data);
export const authorizeMall = (data)=> Api.post(`/ecommerce/oneclick/authorize`, data);
export const transactionStatus = (data)=> Api.post(`/ecommerce/oneclick/status`, data);
export const refund = (data)=> Api.post(`/ecommerce/oneclick/refund`, data);
export const deleteInscription = (data)=> Api.post(`/ecommerce/oneclick/delete`, data);

//User
export const createUserOneClick = (data)=> Api.post(`/ecommerce/users-oneclick`, data);
export const deleteUserOneClick = (id)=> Api.delete(`/ecommerce/users-oneclick/${id}`);
export const createUserAddress = (data)=> Api.post(`/ecommerce/users-address`, data);
export const deleteUserAddress = (id)=> Api.delete(`/ecommerce/users-address/${id}`);
export const deleteUser = (id)=> Api.delete(`/ecommerce/users/${id}`);

//Countries
export const fetchCountries = ()=> Api.getNotAuth(`/countries`);
export const fetchRegionsByCountry = (id)=> Api.getNotAuth(`/regions-by-country/${id}`);
export const fetchCitiesByRegions = (id)=> Api.getNotAuth(`/cities-by-region/${id}`);

//orders
export const createOrder = (data)=> Api.post(`/ecommerce/orders`, data);
export const getOrder = (id)=> Api.getNotAuth(`/ecommerce/orders/${id}`, {});

//Settings
export const getSettings = ()=> Api.getNotAuth(`/ecommerce/settings`);